html {
  font-size: 16px;
}
@media (min-width: 767px) {
  html {
    font-size: 20px;
  }
}

body {
  font-family: "Josefin Sans", sans-serif;
  color: #000;
  background-color: #b4c9de;
  font-weight: 500;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
